import { ContainedBtn } from '@components/Buttons';
import { mc, StyleProps } from '@styles';
import { isStr, isStrArr, TestIdProps } from '@utils';
import React, { FC, ReactNode } from 'react';

interface Props extends StyleProps, TestIdProps {
  title?: string | string[] | ReactNode;
  eyebrow?: string | string[] | ReactNode;
  description?: string | string[] | ReactNode;
  actionBtn?: { label: string; to: string };
}

export const HomeSectionHeader: FC<Props> = ({ testId, className, style, title, eyebrow, description, actionBtn }) => {
  const renderContent = (content: string | string[] | ReactNode) => {
    if (isStr(content)) return content;
    if (isStrArr(content)) {
      const els: ReactNode[] = [];
      for (let i = 0; i < content.length; i++) {
        if (i > 0) els.push(<br key={`${i}-br`} />);
        els.push(<span key={i}>{content[i]}</span>);
      }
      return els;
    }
    return content;
  };
  return (
    <div
      data-testid={testId}
      className={mc('px-8 lg:px-0', 'flex flex-col lg:items-center lg:text-center', className)}
      style={style}
    >
      {!!eyebrow && (
        <div className={mc('font-raleway leading-normal tracking-wide font-bold text-accent-green')}>
          {renderContent(eyebrow)}
        </div>
      )}
      {!!title && (
        <h2 className={mc('m-0 p-0 mt-2', 'text-3xl lg:text-5xl font-literata leading-tight font-medium text-ocean-blue')}>
          {renderContent(title)}
        </h2>
      )}
      {!!description && (
        <div className={mc('m-0 p-0 mt-4', 'text-lg font-raleway leading-normal font-medium text-ocean-blue')}>
          {renderContent(description)}
        </div>
      )}
      {!!actionBtn && (
        <div className={mc('mt-8', 'flex items-center lg:justify-center')}>
          <ContainedBtn href={actionBtn.to} color="orange" size="md">
            {actionBtn.label}
          </ContainedBtn>
        </div>
      )}
    </div>
  );
};

export type HomeSectionHeaderProps = Props;
export default HomeSectionHeader;
