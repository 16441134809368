import { ResourceСategoriesMenu } from '@components/Resources';
import { featureFlags } from '@core/consts';
import { ResourceCategory } from '@core/grapql';
import { mc, StyleProps } from '@styles';
import { compact, TestIdProps } from '@utils';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

type Props = StyleProps & TestIdProps;

export const HomeHeroBannerSection: FC<Props> = ({ style, className, testId }) => {
  const items: ResourceCategory[] = compact([
    {
      id: 1,
      level: 0,
      name: 'Care Support',
      color: '#fff',
      backgroundColor: '#159595',
    },
    featureFlags.financeMegaCategoryEnabled
      ? {
          id: 2,
          level: 0,
          name: 'Finance',
          color: '#fff',
          backgroundColor: '#159595',
        }
      : undefined,
    {
      id: 3,
      level: 0,
      name: 'Health',
      color: '#fff',
      backgroundColor: '#159595',
    },
    {
      id: 4,
      level: 0,
      name: 'Housing',
      color: '#fff',
      backgroundColor: '#159595',
    },
    {
      id: 5,
      level: 0,
      name: 'Legal',
      color: '#fff',
      backgroundColor: '#159595',
    },
  ]);

  return (
    <div data-testid={testId} style={style} className={mc('bg-seafoam relative items-center flex flex-col', className)}>
      <div className="pt-6 lg:pt-0">
        <div className="flex flex-col lg:flex-row lg:items-center justify-end pt-2 lg:pt-12">
          <div className="lg:mr-20 px-8 lg:pl-20 lg:pr-0 ml-2 w-full md:w-8/12 lg:w-6/12 lg:-mt-12 text-left">
            {' '}
            {/* Pixel perfect as per design */}
            <h1 className="leading-none	text-5xl xl:text-7xl mb-6 block">Family caregiving starts here</h1>
            <p className="xl:text-lg text-ocean-blue font-semibold">
              Get control of your time and find the support you need now.
            </p>
          </div>

          <div className="flex flex-row justify-end lg:block mt-8 lg:mt-0">
            <StaticImage
              src="./assets/hero.png"
              alt="Family caregiving starts here"
              layout="constrained"
              height={563}
              quality={100}
              backgroundColor="#D0EBEB"
            />
          </div>
        </div>
      </div>

      <div className={mc('px-8', 'absolute -bottom-[64px]', 'flex flex-row justify-center')}>
        <ResourceСategoriesMenu color="white" items={items} linkType="link" />
      </div>
    </div>
  );
};

export default HomeHeroBannerSection;
