import HomeSectionHeader from '@components/Home/SectionHeader';
import { PostHorizontalCard } from '@components/Post';
import PostVerticalCard from '@components/Post/VerticalCard';
import { PostBase } from '@core/content';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import React, { FC, ReactNode } from 'react';

interface Props extends StyleProps, TestIdProps {
  title?: string | string[] | ReactNode;
  eyebrow?: string | string[] | ReactNode;
  description?: string | string[] | ReactNode;
  mainPost?: PostBase;
  secondSubheader?: string;
  secondaryPosts: PostBase[];
  callToActionLabel?: string;
  callToActionUrl?: string;
}

export const HomeTipsPostsSection: FC<Props> = ({
  testId,
  className,
  title,
  eyebrow,
  secondSubheader,
  mainPost,
  secondaryPosts,
  description,
  callToActionLabel,
  callToActionUrl,
}) => {
  return (
    <div data-testid={testId} className={mc(className)}>
      <div className="pt-24 pb-0 lg:pt-16 lg:pb-16">
        <HomeSectionHeader
          eyebrow={eyebrow}
          title={title}
          description={description}
          actionBtn={!!callToActionLabel && !!callToActionUrl ? { label: callToActionLabel, to: callToActionUrl } : undefined}
        />
      </div>
      <div className={mc('hidden lg:block', 'w-full h-[1px]', 'bg-neutral-gray')} />
      <div className={mc('max-w-[1290px]', 'mx-auto pt-8 lg:pt-20 pb-8 lg:pb-16 px-8 lg:px-4')}>
        <div className="flex flex-col lg:flex-row justify-between lg:-mx-4">
          <div className={mc('w-full lg:w-6/12', 'lg:px-4 lg:-my-4')}>
            {mainPost && <PostVerticalCard post={mainPost} dateFormat="MMMM yyyy" />}
          </div>
          <div className={mc('hidden lg:block', 'w-full lg:w-6/12', 'lg:px-4 lg:-my-4', 'relative')}>
            {!!secondSubheader && (
              <div
                className={mc(
                  'hidden lg:block',
                  'absolute -top-8',
                  'font-raleway leading-normal tracking-wide font-bold text-viridian-green',
                )}
              >
                {secondSubheader}
              </div>
            )}
            {secondaryPosts.map((post, index) => (
              <PostHorizontalCard key={post.slug} className={mc(index > 0 && 'mt-7')} post={post} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export type HomeTipsPostsSectionProps = Props;
export default HomeTipsPostsSection;
