import { HomeSectionHeader } from '@components/Home';
import PostVerticalCard from '@components/Post/VerticalCard';
import { PostBase } from '@core/content';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import React, { FC, Fragment, ReactNode } from 'react';

interface Props extends StyleProps, TestIdProps {
  title?: string;
  eyebrow?: string;
  description?: string | ReactNode;
  posts: PostBase[];
  callToActionLabel?: string;
  callToActionUrl?: string;
}

export const HomeGuidePostsSection: FC<Props> = ({
  testId,
  className,
  posts,
  title,
  eyebrow,
  description,
  callToActionLabel = 'View More',
  callToActionUrl = '#',
}) => {
  return (
    <div data-testid={testId} className={mc('py-16', className)}>
      <HomeSectionHeader
        eyebrow={eyebrow}
        title={title}
        description={description}
        actionBtn={{ label: callToActionLabel, to: callToActionUrl }}
      />
      <div className={mc('mt-16 -mx-8', 'flex flex-col lg:flex-row justify-center items-center lg:items-stretch')}>
        {posts.map((post, index) => (
          <Fragment key={post.slug}>
            <PostVerticalCard className={mc('mx-8 mb-12 lg:mb-0', 'w-[300px] sm:w-[386px] ')} post={post} />
            {index !== posts.length - 1 && <div className={mc('hidden lg:block', 'w-px basis-px', 'bg-neutral-gray')} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export type HomeGuidePostsSectionProps = Props;
export default HomeGuidePostsSection;
