import './styles.css';

import { ContainedBtn } from '@components/Buttons';
import { SectionSubheader } from '@components/Layouts';
import { navigateAppStore } from '@navigation';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import BellIcon from './assets/bell.inline.svg';
import CalendarIcon from './assets/calendar.inline.svg';
import CameraIcon from './assets/camera.inline.svg';
import DocumentsIcon from './assets/documents.inline.svg';
import GroupIcon from './assets/group.inline.svg';
import HandIcon from './assets/hand.inline.svg';
import LockIcon from './assets/lock.inline.svg';
import PenIcon from './assets/pen.inline.svg';
import HomeHighlightsItem from './components/Item';

type Props = StyleProps & TestIdProps;

export const HomeHighlightsSection: FC<Props> = ({ testId, className, style }) => {
  const handleGetAppClick = () => {
    navigateAppStore(() => {
      const element = document.getElementById('app-download-footer');
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  };

  return (
    <section data-testid={testId} style={style} className={mc('bg-light-gray', className)}>
      <div className="container mx-auto px-8 lg:pl-16 lg:pr-0">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="w-full lg:w-6/12 xl:w-7/12 mt-16 xl:mt-32 pb-6 lg:pb-16 xl:pb-36">
            <SectionSubheader className="text-left" title="Download CircleOf" />
            <h2 className="mb-0 mt-0 text-left text-3xl md:text-5xl">Manage time, communicate, and plan around care</h2>

            <p className="text-lg text-ocean-blue font-medium mt-6 md:hidden">
              Take control of your time and resources by strategically planning and prioritizing your caregiving tasks.{' '}
            </p>

            <div className=" md:hidden flex flex-row justify-start mt-8">
              <ContainedBtn onClick={handleGetAppClick} className="mt-0" color="orange">
                {'Get the App'}
              </ContainedBtn>
            </div>

            <div className="md:hidden -mx-8 mt-4">
              <div className="lg:hidden w-full relative highlights-container">
                <StaticImage aspectRatio={0.5} alt="Connect, collaborate, manage securely" src="./assets/highlights-mobile.png" />
              </div>
            </div>

            <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row mt-4 mb-2 lg:mt-16 lg:mb-10">
              <ul className="w-full md:w-1/2 lg:w-full xl:w-1/2 flex flex-col items-start md:items-center md:block">
                <HomeHighlightsItem className="mb-6" title="Post on Care Timeline" icon={<PenIcon />} />
                <HomeHighlightsItem className="mb-6" title="Coordinate Rides, Meals, Shopping" icon={<GroupIcon />} />
                <HomeHighlightsItem className="mb-6" title="Auto-Sync Shared Calendars" icon={<CalendarIcon />} />
                <HomeHighlightsItem className="mb-6" title="Store & Share Documents" icon={<DocumentsIcon />} />
              </ul>

              <ul className="w-full md:w-1/2 lg:w-full xl:w-1/2 flex flex-col items-start md:items-center md:block">
                <HomeHighlightsItem className="mb-6 hidden md:flex" title="Share News & Updates" icon={<BellIcon />} />
                <HomeHighlightsItem className="mb-6" title="Find Answers & Resources" icon={<HandIcon />} />
                <HomeHighlightsItem className="mb-6 hidden md:flex" title="Free, Private, Secure" icon={<LockIcon />} />
                <HomeHighlightsItem className="mb-6 hidden md:flex" title="Private Chat & Video Calls" icon={<CameraIcon />} />
              </ul>
            </div>

            <div className="hidden md:flex flex-row justify-center lg:block">
              <ContainedBtn onClick={handleGetAppClick} className="mt-0" color="orange">
                {'Get the App'}
              </ContainedBtn>
            </div>
          </div>

          <div className="hidden md:block w-full lg:w-7/12 xl:w-6/12 relative">
            <StaticImage
              className="lg:absolute mr-10 iphone"
              alt="Connect, collaborate, manage securely"
              src="./assets/IPhone.png"
              width={330}
            />

            <StaticImage
              className="lg:absolute home"
              alt="Connect, collaborate, manage securely"
              src="./assets/home.png"
              width={330}
            />

            <StaticImage
              className="lg:absolute calendar"
              alt="Connect, collaborate, manage securely"
              src="./assets/Calendar.png"
              width={330}
            />

            <StaticImage
              className="lg:absolute collaborate"
              alt="Connect, collaborate, manage securely"
              src="./assets/connect-collaborate.png"
              width={330}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHighlightsSection;
