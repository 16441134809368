import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import React, { FC, MouseEventHandler } from 'react';
import ArrowLeftIcon from './assets/arrowLeft.inline.svg';
import ArrowRightIcon from './assets/arrowRight.inline.svg';

interface Props extends StyleProps, TestIdProps {
  icon: 'left' | 'right';
  size?: 'sm' | 'md';
  onClick?: () => void;
}

export const TipsForCaregiversBtn: FC<Props> = ({ testId, className, style, icon, size = 'md', onClick }) => {
  const handleClick: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };
  return (
    <a
      data-testid={testId}
      className={mc(
        'block',
        size === 'sm' && 'w-10 h-10',
        size === 'md' && 'w-16 h-16',
        'flex justify-center items-center',
        'rounded-full',
        'cursor-pointer',
        'bg-viridian-green',
        'hover:opacity-80',
        className,
      )}
      style={style}
      role="button"
      onClick={handleClick}
    >
      {icon === 'left' && <ArrowLeftIcon />}
      {icon === 'right' && <ArrowRightIcon />}
    </a>
  );
};

export type TipsForCaregiversBtnProps = Props;
export default TipsForCaregiversBtn;
