import { ContainedBtn } from '@components/Buttons';
import { SectionSubheader } from '@components/Layouts';
import { navigateAppStore } from '@navigation';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import HandHeartIcon from './assets/icons/hand-h.inline.svg';
import HandIcon from './assets/icons/hand.inline.svg';
import LoopIcon from './assets/icons/loop.inline.svg';
import PointerIcon from './assets/icons/pointer.inline.svg';
import PuzzleIcon from './assets/icons/puzzle.inline.svg';
import AnimatedShowcaseTile from './components/Tile';

type Props = StyleProps & TestIdProps;

export const HomeAnimatedShowcasesSection: FC<Props> = ({ style, className, testId }) => {
  const isSmallScreenSize = useMediaQuery('(max-width: 768px)');
  const interval = useRef<NodeJS.Timer>();
  const intervalTimeout = useRef<NodeJS.Timeout>(); // When element clicked, hold on/delay with interval
  const lastSelectedIndex = useRef<number>(1); // Initial
  const [selected, setSelected] = useState<number>(1);

  useEffect(() => {
    startChangeInterval();
  }, []);

  const startChangeInterval = () => {
    const every = 3 * 1000; //3 seconds

    // Clear previous
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      if (lastSelectedIndex.current >= 5) {
        lastSelectedIndex.current = 1;
        return setSelected(1); // Reset and start from the beginning
      }

      const nextValue = lastSelectedIndex.current + 1;

      setSelected(nextValue);
      lastSelectedIndex.current = nextValue;
    }, every);
  };

  const handleElementClick = (index: number) => {
    clearTimeout(intervalTimeout.current);
    clearInterval(interval.current);

    setSelected(index);
    lastSelectedIndex.current = index;

    // https://circleof.atlassian.net/browse/APP-2959
    // Should scroll to top of the view on mobile devices
    if (isSmallScreenSize) {
      const element = document.getElementById('phoneCase');
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    intervalTimeout.current = setTimeout(() => {
      startChangeInterval();
    }, 10 * 1000); // Just delay by 10 seconds
  };

  const handleCreateCircleClick = () => {
    navigateAppStore(() => {
      const element = document.getElementById('app-download-footer');
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  };

  const getShowcaseTitle = (index: number) => {
    switch (index) {
      case 1:
        return 'Build a team and get help';
      case 2:
        return 'Organize it all in one place';
      case 3:
        return 'Coordinate your volunteers';
      case 4:
        return 'Keep everyone in the loop';
      case 5:
        return 'Connect to helpful resources';
      default:
        return '';
    }
  };

  const renderFrames = () => {
    return (
      <>
        <div className={selected === 1 ? 'block' : 'hidden'}>
          <div className="absolute right-0" style={{ zIndex: 1, right: '-58%', top: '47%', width: '100%', height: '16%' }}>
            <StaticImage
              className="absolute"
              src="./assets/phone-frames/build-a-team/frame1.png"
              alt={getShowcaseTitle(selected)}
            />
          </div>

          <div className="absolute right-0" style={{ zIndex: 1, right: '-48%', top: '65%', width: '100%', height: '16%' }}>
            <StaticImage
              className="absolute"
              src="./assets/phone-frames/build-a-team/frame2.png"
              alt={getShowcaseTitle(selected)}
            />
          </div>

          <StaticImage
            // className="absolute right-0"
            src="./assets/phone-frames/build-a-team/build-a-team-frame.png"
            alt={getShowcaseTitle(selected)}
          />
        </div>
        <div className={selected === 2 ? 'block' : 'hidden'}>
          <div className="absolute right-0" style={{ zIndex: 1, right: '-50%', top: '44%', width: '100%', height: '16%' }}>
            <StaticImage className="absolute" src="./assets/phone-frames/organize/frame2.png" alt={getShowcaseTitle(selected)} />
          </div>

          <div className="absolute right-0" style={{ zIndex: 1, right: '-28%', bottom: '13%', width: '100%', height: '16%' }}>
            <StaticImage className="absolute" src="./assets/phone-frames/organize/frame1.png" alt={getShowcaseTitle(selected)} />
          </div>

          <StaticImage src="./assets/phone-frames/organize/organize-it-all.png" alt={getShowcaseTitle(selected)} />
        </div>
        <div className={selected === 3 ? 'block' : 'hidden'}>
          <div className="absolute right-0" style={{ zIndex: 1, left: '2%', top: '20%', width: '100%', height: '14%' }}>
            <StaticImage
              className="absolute"
              src="./assets/phone-frames/coordinate/frame2.png"
              alt={getShowcaseTitle(selected)}
            />
          </div>

          <div className="absolute right-0" style={{ zIndex: 1, left: '30%', top: '36%', width: '100%', height: '16%' }}>
            <StaticImage
              className="absolute"
              src="./assets/phone-frames/coordinate/frame1.png"
              alt={getShowcaseTitle(selected)}
            />
          </div>

          <StaticImage
            style={{ marginTop: '-5%' }}
            src="./assets/phone-frames/coordinate/coordinate.png"
            alt={getShowcaseTitle(selected)}
          />
        </div>
        <div className={selected === 4 ? 'block' : 'hidden'}>
          <div className="absolute right-0" style={{ zIndex: 1, left: '35%', top: '41%', width: '100%', height: '8%' }}>
            <StaticImage
              layout="constrained"
              className="absolute"
              src="./assets/phone-frames/loop/frame2.png"
              alt={getShowcaseTitle(selected)}
            />
          </div>

          <div className="absolute right-0" style={{ zIndex: 1, left: '7%', bottom: '17%', width: '100%', height: '16%' }}>
            <StaticImage className="absolute" src="./assets/phone-frames/loop/frame1.png" alt={getShowcaseTitle(selected)} />
          </div>

          <StaticImage src="./assets/phone-frames/loop/keep-in-the-loop.png" alt={getShowcaseTitle(selected)} />
        </div>
        <div className={selected === 5 ? 'block' : 'hidden'}>
          <StaticImage src="./assets/phone-frames/resources.png" alt={getShowcaseTitle(selected)} />
        </div>
      </>
    );
  };

  return (
    <div data-testid={testId} style={style} className={mc('bg-light-gray', className)}>
      <div id="phoneCase" className="container mx-auto lg:pb-0">
        <div className="flex flex-col lg:w-full lg:flex-row justify-between">
          {/* Image */}
          <div
            className={mc(
              'hidden lg:block',
              'pb-6 lg:pb-0 lg:px-6 xl:px-0 mt-20',
              'w-full xl:w-6/12',
              'relative overflow-hidden',
            )}
          >
            <div className="relative -ml-16 lg:-ml-0 mr-16">
              <div className="absolute w-full h-full" style={{ width: '40%', height: '69.2%', right: '13.2%', top: '1.6%' }}>
                {renderFrames()}
              </div>
              <StaticImage
                src="./assets/hand-phone-case.png"
                alt="A place for family to gather and connect around care"
                layout="fullWidth"
              />
            </div>
          </div>
          {/* Image End */}
          {/* Text */}
          <div
            className={mc(
              'w-full lg:w-6/12 xl:w-4/12',
              'px-8 lg:px-0',
              'lg:mr-12 xl:mr-28',
              'flex flex-col items-start lg:items-center lg:block',
            )}
          >
            <div className="text-left">
              <SectionSubheader title="Extra Help In Your Hands" />
              <h2 className="mt-0 text-3xl md:text-5xl">A place for family to gather and connect around care</h2>
            </div>
            <div className={mc('lg:hidden', 'mt-6', 'text-lg font-raleway leading-normal font-medium text-ocean-blue')}>
              Extra help is in your hands. See your day organized in one place, make changes, collaborate and get volunteers.
            </div>
            <ul className={mc('hidden lg:block', 'flex flex-col items-start lg:items-center', 'divide-y divide-gray-200')}>
              <AnimatedShowcaseTile
                index={1}
                selected={selected === 1}
                icon={<PuzzleIcon />}
                onClick={handleElementClick}
                title={getShowcaseTitle(1)}
              />
              <AnimatedShowcaseTile
                index={2}
                selected={selected === 2}
                icon={<PointerIcon />}
                onClick={handleElementClick}
                title={getShowcaseTitle(2)}
              />
              <AnimatedShowcaseTile
                index={3}
                selected={selected === 3}
                icon={<HandIcon />}
                onClick={handleElementClick}
                title={getShowcaseTitle(3)}
              />
              <AnimatedShowcaseTile
                index={4}
                selected={selected === 4}
                icon={<LoopIcon />}
                onClick={handleElementClick}
                title={getShowcaseTitle(4)}
              />
              <AnimatedShowcaseTile
                index={5}
                selected={selected === 5}
                icon={<HandHeartIcon />}
                onClick={handleElementClick}
                title={getShowcaseTitle(5)}
              />
            </ul>

            <ContainedBtn onClick={handleCreateCircleClick} className="mt-8" color="orange">
              {'Create Your Circle'}
            </ContainedBtn>
          </div>
          {/* Text End */}
          {/* Mobile Image */}
          <StaticImage
            className={mc('lg:hidden', 'mt-20')}
            src="./assets/handMobile.png"
            alt="A place for family to gather and connect around care"
            layout="fullWidth"
          />
          {/* Mobile Image End */}
        </div>
      </div>
    </div>
  );
};

export default HomeAnimatedShowcasesSection;
