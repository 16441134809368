import { PostBase } from '@core/content';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import React, { FC } from 'react';

// type Props = StyleProps & TestIdProps;
interface Props extends StyleProps, TestIdProps {
  post: PostBase;
}

export const TipsForCaregiversPost: FC<Props> = ({ testId, className, style, post }) => {
  const { title: rawTitle, url, headerImage } = post;
  const image = getImage(headerImage as ImageDataLike);
  const title = rawTitle.startsWith('Tips for Caregivers: ') ? rawTitle.replace('Tips for Caregivers: ', '') : rawTitle;
  return (
    <a
      data-testid={testId}
      className={mc(
        'block',
        'flex flex-col',
        'border rounded-lg',
        'overflow-hidden',
        '!text-ocean-blue hover:!text-salmon',
        'hover:shadow-lg',
        'bg-white',
        className,
      )}
      style={style}
      href={url}
    >
      {/** Thumb **/}
      {!!image && <GatsbyImage className={mc('h-72 w-full', 'rounded-lg')} alt={title} image={image} />}
      {/** Thumb End **/}
      {/** Info **/}
      <div className="p-4">
        <div className={mc('font-raleway leading-normal tracking-wide font-bold text-viridian-green', 'lg:text-center')}>
          {'Tips for Caregivers'}
        </div>
        <h6 className={mc('m-0 p-0 mt-2', 'text-xl font-raleway leading-normal font-bold text-inherit', 'lg:text-center')}>
          {title}
        </h6>
      </div>
      {/** Info End **/}
    </a>
  );
};

export type TipsForCaregiversPostProps = Props;
export default TipsForCaregiversPost;
