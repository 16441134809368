import { SeoHeader } from '@components/Common';
import { HomeHeroBannerSection, HomeHighlightsSection } from '@components/Home';
import { AppDownloadSection, CookiePolicy } from '@components/Layouts';
import { Footer, NavigationHeader } from '@components/Navigation';
import { PostBase } from '@core/content';
import { Routes } from '@navigation';
import React, { FC } from 'react';

import HomeAnimatedShowcasesSection from './components/AnimatedShowcasesSection';
import HomeGuidePostsSection from './components/GuidePostsSection';
import HomeTipsPostsSection from './components/TipsPostsSection';
import HomeTipsForCaregiversSection from './components/TipsForCaregiversSection';

interface Props {
  pageContext: PageContext;
}

interface PageContext {
  guides: HomePageGuidesSectionContext;
  tips: HomePageTipsSectionContext;
  tipsForCaregivers: HomePageTipsForCaregiversSectionContext;
}

interface HomePageGuidesSectionContext {
  posts: PostBase[];
}

interface HomePageTipsSectionContext {
  eyebrowHeader?: string;
  title?: string;
  subtitle?: string | string[];
  secondEyebrow?: string;
  mainPost?: PostBase;
  secondaryPosts: PostBase[];
}

interface HomePageTipsForCaregiversSectionContext {
  eyebrow?: string;
  title?: string;
  description?: string | string[];
  ctaButton?: { href: string; text: string };
  posts: PostBase[];
}

const IndexPage: FC<Props> = ({ pageContext }) => {
  const { tips: tipsSectionData, tipsForCaregivers } = pageContext;
  return (
    <>
      <div className="overflow-x-hidden lg:overflow-x-auto">
        <NavigationHeader testId="home-header" />
        <CookiePolicy />
        <HomeHeroBannerSection testId="home-hero-banner-section" />
        {/* Tips for Caregivers */}
        {!!tipsForCaregivers.posts.length && (
          <HomeTipsForCaregiversSection
            className="pt-44 lg:pt-32 xl:pt-36"
            eyebrow={tipsForCaregivers.eyebrow}
            title={tipsForCaregivers.title}
            description={tipsForCaregivers.description}
            posts={tipsForCaregivers.posts}
          />
        )}
        <HomeAnimatedShowcasesSection className="pt-24 lg:pt-16" testId="home-animated-showcases-section" />
        {/* Tips Posts */}
        {(!!tipsSectionData.mainPost || !!tipsSectionData.secondaryPosts.length) && (
          <HomeTipsPostsSection
            testId="home-tips-post-section"
            eyebrow={tipsSectionData.eyebrowHeader || 'Curated Solutions'}
            secondSubheader={tipsSectionData.secondEyebrow || 'Related Articles'}
            title={tipsSectionData.title || ['Caregiving Tips & Solutions:', 'Falls & Fractures']}
            description={
              tipsSectionData.subtitle || [
                'Are you caring for someone who is recovering from a recent fall or fracture?',
                'Get tips and find help now.',
              ]
            }
            mainPost={tipsSectionData.mainPost}
            secondaryPosts={tipsSectionData.secondaryPosts}
          />
        )}
        {/* Tips Posts End */}
        <HomeHighlightsSection testId="home-highlights-section" />
        {/* Guide Posts */}
        {!!pageContext.guides.posts.length && (
          <HomeGuidePostsSection
            testId="home-posts-section"
            posts={pageContext.guides.posts}
            eyebrow="Guides & Tips"
            title="Sharing is caring"
            description="We see you and we’re here to help you reduce the stress and overload of caring for loved ones."
            callToActionLabel="View More"
            callToActionUrl={Routes.guidesAndTips.url}
          />
        )}
        {/* Guide Posts End */}
        {/* <HomeTestimonialsSection testId="home-testimonials-section" /> */}
        <AppDownloadSection testId="home-app-download-section" />
        <Footer testId="home-footer" />
      </div>
    </>
  );
};

export const Head = () => <SeoHeader title="Home" />;

export default IndexPage;
