import { HomeSectionHeader } from '@components/Home';
import { PostBase } from '@core/content';
import { mc, StyleProps } from '@styles';
import { TestIdProps } from '@utils';
import React, { FC, ReactNode, useRef } from 'react';
import Slider from 'react-slick';
import * as styles from './tipsForCaregiversSection.module.css';

import TipsForCaregiversPost from './components/Post';
import TipsForCaregiversBtn from './components/Btn';

interface Props extends StyleProps, TestIdProps {
  title?: string;
  eyebrow?: string;
  description?: string | ReactNode;
  posts: PostBase[];
  // callToActionLabel?: string;
  // callToActionUrl?: string;
}

export const HomeTipsForCaregiversSection: FC<Props> = ({
  testId,
  className,
  posts,
  title,
  eyebrow,
  description,
  // callToActionLabel = 'View More',
  // callToActionUrl = '#',
}) => {
  const desktopRef = useRef<Slider>(null);
  const mobileRef = useRef<Slider>(null);

  const handleDesktopNavigateClick = (direction: 'prev' | 'next') => () => {
    const slider = desktopRef.current;
    if (!slider) return;
    if (direction === 'prev') {
      slider.slickPrev();
    } else {
      slider.slickNext();
    }
  };

  const handleMobileNavigateClick = (direction: 'prev' | 'next') => () => {
    const slider = mobileRef.current;
    if (!slider) return;
    if (direction === 'prev') {
      slider.slickPrev();
    } else {
      slider.slickNext();
    }
  };

  return (
    <div data-testid={testId} className={mc('py-16', className)}>
      <HomeSectionHeader
        eyebrow={eyebrow}
        title={title}
        description={description}
        // actionBtn={{ label: callToActionLabel, to: callToActionUrl }}
      />
      {/** Desktop Slider */}
      <div className={mc('hidden lg:block', 'mt-16 px-36', 'relative')}>
        <TipsForCaregiversBtn
          className={mc('absolute z-10', 'top-1/2 left-12')}
          icon="left"
          onClick={handleDesktopNavigateClick('prev')}
        />
        <div>
          <Slider ref={desktopRef} slidesToShow={3} infinite speed={500} waitForAnimate>
            {posts.map((post, index) => (
              <div key={post.slug} className={mc('w-full', 'px-4', index > 0 && 'border-l')}>
                <TipsForCaregiversPost className={mc('w-full')} post={post} />
              </div>
            ))}
          </Slider>
        </div>
        <TipsForCaregiversBtn
          className={mc('absolute z-10', 'top-1/2 right-12')}
          icon="right"
          onClick={handleDesktopNavigateClick('next')}
        />
      </div>
      {/** Desktop Slider End */}
      {/** Desktop Mobile */}
      <div className={mc('lg:hidden', 'mt-9 px-16', 'relative')}>
        <TipsForCaregiversBtn
          className={mc('absolute z-10', 'top-1/2 left-3')}
          size="sm"
          icon="left"
          onClick={handleMobileNavigateClick('prev')}
        />
        <div>
          <Slider ref={mobileRef} dotsClass={styles.dots} slidesToShow={1} dots infinite speed={500} waitForAnimate>
            {posts.map(post => (
              <div key={post.slug} className={mc('w-full', 'px-4')}>
                <TipsForCaregiversPost className={mc('w-full')} post={post} />
              </div>
            ))}
          </Slider>
        </div>
        <TipsForCaregiversBtn
          className={mc('absolute z-10', 'top-1/2 right-3')}
          size="sm"
          icon="right"
          onClick={handleMobileNavigateClick('next')}
        />
      </div>
      {/** Desktop Mobile End */}
    </div>
  );
};

export type HomeGuidePostsSectionProps = Props;
export default HomeTipsForCaregiversSection;
