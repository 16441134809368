import React, { FC, ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  title: string;
  className?: string;
}

export const HomeHighlightsItem: FC<Props> = ({ icon, title, className }) => {
  return (
    <li className={`flex flex-row items-center ${className}`}>
      <div className="mr-4 stroke-ocean-blue bg-seafoam rounded-full">{icon}</div>

      <span className="block text-ocean-blue font-semibold">{title}</span>
    </li>
  );
};

export default HomeHighlightsItem;
