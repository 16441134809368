import React, { FC, ReactNode } from 'react';

interface Props {
  icon?: ReactNode;
  title: string;
  selected?: boolean;
  index: number;
  onClick: (index: number) => void;
}

export const HomeAnimatedShowcaseTile: FC<Props> = ({ title, icon, selected = false, index, onClick }) => {
  const handleOnClick = () => {
    onClick(index);
  };

  return (
    <a
      onMouseEnter={handleOnClick}
      role="button"
      onClick={handleOnClick}
      className={`hover:text-accent-orange stroke-ocean-blue py-6 px-4 lg:py-3 lg:px-2 xl:px-4 xl:py-6 flex flex-row items-center text-ocean-blue ${
        selected ? 'bg-white text-accent-orange stroke-accent-green' : ''
      }`}
    >
      <div className="flex justify-start mr-4">{icon}</div>
      <span className={`tracking-wide xl:text-lg  font-semibold`}>{title}</span>
    </a>
  );
};

export default HomeAnimatedShowcaseTile;
